<template>
  <component :is="componentInstance" v-if="componentHide" ref="componentRef"></component>
  <notify-msg-dialog ref="notifyMsgDialogRef"></notify-msg-dialog>
</template>

<script setup lang="tsx">
import { defineAsyncComponent, nextTick, Ref, ref, shallowRef, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

import type { IGetNotifiyMsgListItem } from '@/api/model/globalNotificationModel';

import NotifyMsgDialog from './NotifiyMsgDialog.vue';

const router = useRouter();

const configComponentByCode = [
  {
    codes: ['supplier_order_cancel'],
    component: defineAsyncComponent(
      () => import('@/pages/orderManagement/list/components/OrderDetailDrawer/index.vue'),
    ),
  },
  {
    codes: [
      'srm_order_aftersale_refund_and_return_audit',
      'srm_order_aftersale_apply',
      'srm_order_aftersale_warranty_audit',
      'srm_order_aftersale_warranty_apply',
    ],
    component: defineAsyncComponent(
      () => import('@/pages/orderManagement/afterSales/components/AfterSalesDetailDrawer/index.vue'),
    ),
  },
  {
    codes: ['srm_order_invoice_apply'],
    component: defineAsyncComponent(
      () => import('@/pages/orderManagement/invoicingManagement/components/AuditInvoiceDrawer.vue'),
    ),
  },
  {
    codes: ['srm_order_express_exception'],
    component: defineAsyncComponent(() => import('@/pages/enhancedService/components/CheckLogisticsDrawer.vue')),
    open(params: IGetNotifiyMsgListItem, ComRef?: Ref<any>) {
      const { msgBizParam, linkId } = params;
      ComRef?.value?.open(linkId, msgBizParam?.businessBatchNo);
    },
  },
  {
    codes: ['srm_off_shelf_product_by_bid', 'srm_off_shelf_product_by_saas'],
    open(params: IGetNotifiyMsgListItem) {
      router.push({
        path: '/merchandise-management/on-sale',
        query: {
          status: 'offShelf',
          productId: params.linkId,
        },
      });
    },
  },
  {
    codes: [
      'srm_product_supply_pass',
      'srm_product_supply_reject',
      'srm_product_supply_reject_batch',
      'srm_product_bid_pass',
      'srm_product_bid_reject',
      'srm_product_bid_reject_batch',
      'srm_product_bid_reject_on_product_off',
    ],
    component: defineAsyncComponent(
      () => import('@/pages/merchandiseManagement/supply/components/Drawer/SupplyRequestRecordsDrawer.vue'),
    ),

    open(params: IGetNotifiyMsgListItem, ComRef?: Ref<any>) {
      ComRef?.value.open({
        shopProductSupplyId: params.msgBizParam.supplyId,
        status: params.msgBizParam.status,
      });
    },
  },
  {
    codes: ['srm_merchant_stock_warn', 'srm_warehouse_stock_warn'],
    open(params: IGetNotifiyMsgListItem) {
      router.push({
        path: '/merchandise-management/on-sale',
        query: {
          productId: params.linkId,
        },
      });
    },
  },
];

const data = ref<IGetNotifiyMsgListItem | null>(null);
const notifyMsgDialogRef = ref<InstanceType<typeof NotifyMsgDialog> | null>(null);

const componentInstance = shallowRef<ReturnType<typeof defineAsyncComponent> | null>(null);
const componentHide = ref(false);

// 筛出所有configComponentByCode中的业务code
const openNoifyDialogCode = configComponentByCode.map((v) => v.codes).flat(1);
const open = async (params: IGetNotifiyMsgListItem) => {
  // 如果业务code没有特殊处理，就直接打开消息弹窗统一展示
  if (!openNoifyDialogCode.includes(params.notifyCode)) {
    notifyMsgDialogRef.value?.open(params);
    return;
  }
  componentHide.value = false;
  data.value = params;
  await nextTick();

  const componentConfigObj = configComponentByCode.find((v) => v.codes.includes(params.notifyCode));
  if (!componentConfigObj) return;

  const { component, open } = componentConfigObj;

  if (!component && open) {
    open(params);
    return;
  }

  componentInstance.value = component;
  componentHide.value = true;
};

const componentRef = ref<any>(null);
watchEffect(() => {
  if (typeof componentRef.value?.open === 'function') {
    const timeId = setTimeout(() => {
      const componentConfigObj = configComponentByCode.find((v) => v.codes.includes(data.value.notifyCode));
      if (!componentConfigObj) return;

      const { open } = componentConfigObj;
      if (open) {
        open(data.value, componentRef);
      } else {
        componentRef?.value?.open(data.value?.linkId);
      }

      clearTimeout(timeId);
    }, 0);
  }
});

defineExpose({
  open,
});
</script>

<style lang="less" scoped></style>
