import { request } from '@/utils/request';

import type { IMiniProgramShareQRCodeRequest, MiniProgramShareQRCodeResponse } from './model/serviceCenterModel';

const Api = {
  GetCosCredentials: '/service/center/txcloud/common/cos/sign',
  GetCosPublicCredentials: '/service/center/txcloud/common/cos/public/sign',
  GetImSign: '/service/center/im/sign',
  GetDictionariesByCode: '/service/center/dict/manage/list',
  GetOrderExpressList: '/service/center/express',
  GetMiniProgramShareQRCode: '/service/center/share/generate',
  GetExpressByNum: '/service/center/express/checkno',
  GetChindrenChinaAreasByCode: '/service/center/location/china/areas/children',
  GetChinaAreasByParams: '/service/center/location/china/areas/parse',
};

export function getCosCredentials() {
  return request.get({
    url: Api.GetCosCredentials,
  });
}

export function GetCosPublicCredentials() {
  return request.get({
    url: Api.GetCosPublicCredentials,
  });
}

// 根据code获取下级行政区划
export function GetChindrenChinaAreasByCode(code?: string | null) {
  return request.get({
    url: Api.GetChindrenChinaAreasByCode,
    params: code ? { parentCode: code } : {},
  });
}

// 通过code或者name查询行政区划
export function GetChinaAreasByParams(params: { addressStr: string, addressType: 'code' | 'name' }) {
  return request.get({
    url: Api.GetChinaAreasByParams,
    params,
  });
}

// 获取即时通信sign
export function getImSign(userId: string) {
  return request.get({
    url: `${Api.GetImSign}/${userId}`,
  });
}

export function GetDictionariesByCode(codes: string[]) {
  return request.get({
    url: Api.GetDictionariesByCode,
    params: {
      codes,
    },
  });
}

export function GetOrderExpressList(businessBatchNo: string) {
  return request.get({
    url: Api.GetOrderExpressList,
    params: `/${businessBatchNo}`,
  });
}

// 获取小程序二维码
export function GetMiniProgramShareQRCode(data: IMiniProgramShareQRCodeRequest) {
  return request.post<MiniProgramShareQRCodeResponse>({
    url: Api.GetMiniProgramShareQRCode,
    data,
  });
}

// 根据用户搜索的单号匹配可用快递公司
export function getExpressByNum(waybillNo: string) {
  return request.get({
    url: Api.GetExpressByNum,
    params: {
      waybillNo,
    },
  });
}
