import { AFTER_SALES_STEP_AUDIT_TITLE_ENUM } from '@laicui/constants';
import { LaiFile, stepItem } from '@laicui/types';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';

import $ChatBus from './commonBus/chat';
import withInstall from './withInstall';
import { watchWebsiteUpate } from './worker/index';

export * from './statusCode';

export { $ChatBus, watchWebsiteUpate, withInstall };

/**
 * 根据进度计算进度条百分比
 *
 * @currentTime 当前进度 秒
 * @duration 视频总时长 秒
 * @return 返回百分比数字
 */
export const updateProgress = (currentTime: number, duration: number): number => {
  return (currentTime / duration) * 100;
};
export const formatTime = (second: number): string => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second % 3600) / 60);
  const seconds = second % 60;

  const hoursStr = hours < 10 ? `0${hours.toString()}` : hours.toString();
  const minutesStr = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();
  const secondsStr = seconds < 10 ? `0${seconds.toString()}` : seconds.toString();

  if (hoursStr === '00') {
    return `${minutesStr}:${secondsStr}`;
  }
  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};
/**
 * 返回一个有兜底值的安全访问对象，当访问的key不存在时，返回一个兜底的值
 * @param targetObject  需要代理的对象
 * @param defaultParam  兜底对象，一般与targetObject的成员一致
 * @returns ProxyObject
 */
export const getSecureObject = <T extends { [key: string | number]: any }>(targetObject: T, defaultParam: any): T => {
  return new Proxy(targetObject, {
    get(target, prop) {
      const propHasTarget = prop in target;
      return propHasTarget ? target[prop as string | number] : defaultParam;
    },
  });
};
/**
 *
 * @param totalSeconds 需要格式化分割的秒数
 * @param segmentCount 需要分割成几段
 * @returns
 */
export const formatTimeSlot = (totalSeconds: number, segmentCount = 4) => {
  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  function generateTimeObject(segmentCount: number) {
    const secondsPerSegment = Math.floor(totalSeconds / segmentCount);
    const timeObject: { [key: number]: any } = {};
    for (let i = 0; i <= segmentCount; i++) {
      const currentSecond: number = i * secondsPerSegment;
      timeObject[currentSecond] = formatTime(currentSecond);
    }
    return timeObject;
  }
  return generateTimeObject(segmentCount);
};

/**
 * 此下载方法适合文件不在oss中的情况，在oss中的文件请使用uploadFileToCos中的下载方法
 * @param fileUrl 文件url
 * @param dowloadName 需要携带后缀
 */
export const downloadUrl = async (fileUrl: string, dowloadName?: string) => {
  const { MessagePlugin } = await import('tdesign-vue-next');
  try {
    MessagePlugin.loading('下载中');
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = link;
    if (dowloadName) {
      a.download = dowloadName; // 设置下载文件的文件名和扩展名
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    MessagePlugin.closeAll();
    MessagePlugin.success('下载成功');
  } catch (error) {
    MessagePlugin.closeAll();
    MessagePlugin.error('下载失败');
    console.error('Error downloading URL:', error);
  }
};

// 导出流数据到excel
export const exportExcelByBlob = async (data: Blob, dowloadName: string) => {
  const { MessagePlugin } = await import('tdesign-vue-next');
  try {
    MessagePlugin.loading('下载中');
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(data);
    const a = document.createElement('a');
    a.href = link;
    a.download = dowloadName; // 设置下载文件的文件名和扩展名
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    MessagePlugin.closeAll();
    MessagePlugin.success('下载成功');
  } catch (error) {
    MessagePlugin.closeAll();
    MessagePlugin.error('下载失败');
    console.error('Error downloading URL:', error);
  }
};

export const isSizeWithinMax = (size: number, maxSize: number, unit: 'B' | 'KB' | 'MB' | 'GB'): boolean => {
  const unitToBytes = new Map([
    ['B', 1],
    ['KB', 1024],
    ['MB', 1024 ** 2],
    ['GB', 1024 ** 3],
  ]);
  const sizeInBytes = size / (unitToBytes.get(unit) || 1);
  return sizeInBytes > maxSize;
};

type formatSearchDateReturn = {
  beginTime?: string;
  endTime?: string;
  auditBeginTime?: string;
  auditEndTime?: string;
  [key: number | string]: any;
};
/**
 * 格式化搜索时间
 * @param searchParams
 * @param customRule
 * @returns  formatSearchDateReturn
 */
export const formatSearchDate = (
  searchParams: { createTime?: string[]; auditTime?: string[]; [key: string]: string[] | any },
  customRule?: { [key: string]: string[] },
) => {
  const reslutRule = {
    createTime: ['beginTime', 'endTime'],
    auditTime: ['auditBeginTime', 'auditEndTime'],
  };
  const reslut = {} as formatSearchDateReturn;
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key] && Array.isArray(searchParams[key])) {
      const reslutRuleArray = customRule ? customRule[key] : reslutRule[key as 'createTime' | 'auditTime'];
      const [firstDate, endDate] = searchParams[key];
      reslut[reslutRuleArray[0]] = firstDate;
      reslut[reslutRuleArray[1]] = endDate;
    }
  });
  return reslut;
};

export const timeConversion = {
  justNow: '刚刚',
  past: (n) => {
    return n.match(/\d/) ? `${n}之前` : n;
  },
  future: (n): string => {
    return n.match(/\d/) ? `${n}之后` : n;
  },
  month: (n, past) => {
    if (n === 1) {
      return past ? '上个月' : '下个月';
    }
    return `${n}月`;
  },
  year: (n, past) => {
    if (n === 1) {
      return past ? '去年' : '明年';
    }
    return `${n}年`;
  },
  day: (n, past) => {
    if (n === 1) {
      return past ? '昨天' : '明天';
    }
    return `${n}天`;
  },
  week: (n, past) => {
    if (n === 1) {
      return past ? '上周' : '下周';
    }
    return `${n}周`;
  },
  hour: (n) => `${n}小时`,
  minute: (n) => `${n}分钟`,
  second: (n) => `${n}秒`,
  invalid: '',
};

// 计算时间戳函数
// calculate timestamp
export const calculateTimestamp = (timestamp: number): string => {
  const todayZero = new Date().setHours(0, 0, 0, 0);
  const thisYear = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime();
  const target = new Date(timestamp);

  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * oneDay;
  // const oneYear = 365 * oneDay;

  const diff = todayZero - target.getTime();

  function formatNum(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  if (diff <= 0) {
    // 今天，只显示小时:分钟
    return `${formatNum(target.getHours())}:${formatNum(target.getMinutes())}`;
  }
  if (diff <= oneDay) {
    // 昨天，显示昨天:小时:分钟
    return `昨天 ${formatNum(target.getHours())}:${formatNum(target.getMinutes())}`;
  }
  if (diff <= oneWeek - oneDay) {
    // 一周内，显示工作日小时:分钟
    const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const weekday = weekdays[target.getDay()];
    return `${weekday} ${formatNum(target.getHours())}:${formatNum(target.getMinutes())}`;
  }
  if (target.getTime() >= thisYear) {
    // 一周以上、年内。显示月/日时:分
    return `${target.getMonth() + 1}/${target.getDate()} ${formatNum(target.getHours())}:${formatNum(
      target.getMinutes(),
    )}`;
  }
  // 不在今年内，显示 年/月/日 时:分
  return `${target.getFullYear()}/${target.getMonth() + 1}/${target.getDate()} ${formatNum(
    target.getHours(),
  )}:${formatNum(target.getMinutes())}`;
};

// 格式化日期格式
export const formatDateTime = (dateTime: string) => {
  return dayjs(dateTime).format('YYYY-MM-DD HH:mm:ss');
};

// 格式化金额
export const formatAmount = (amount: number | string, symbol = '') => {
  const newAmount = new BigNumber(amount ?? 0);
  return `${symbol}${newAmount.toFixed(2)}`;
};

/**
 * 格式化地址的area
 */
export const formatAddressArea = (area: { code: string; name: string }[]): string => {
  return area?.map((item) => item.name)?.join('') || '';
};

type generateAfterSaleStepsParams = {
  createTime: string;
  cancelTime: string;
  afterSaleAuditStatus: string;
  aftersaleAuditTime: string;
  customerRefundTime: string;
  merchantSigningTime: string;
  aftersaleFinishTime: string;
  secondaryState: string;
  aftersaleType: string;
};
export const generateAfterSaleSteps = (
  params: generateAfterSaleStepsParams,
): { current: string; stepItems: stepItem[] } => {
  // 开始构建节点
  // 初始化节点，默认第一个都是客户申请节点
  let current = 'audit';
  const stepItems = [
    {
      value: 'created',
      title: '客户申请',
      content: params.createTime,
    },
  ];
  // 判断是否审核前撤销，显示撤销节点，并结束构建
  if (params.secondaryState === 'wait_undo') {
    current = 'FINISH';
    stepItems.push({
      value: 'undo',
      title: '撤销申请',
      content: params.cancelTime,
    });
    return { stepItems, current };
  }
  // 没撤销则显示审核节点
  stepItems.push({
    value: 'audit',
    title: AFTER_SALES_STEP_AUDIT_TITLE_ENUM[params.afterSaleAuditStatus || 'wait'],
    content: params.aftersaleAuditTime,
  });
  // 如果未审核，结束构建
  if (!params.aftersaleAuditTime) {
    return { stepItems, current };
  }
  // 如果审核结果是拒绝，结束构建
  if (params.afterSaleAuditStatus === 'reject') {
    current = 'FINISH';
    return { stepItems, current };
  }
  // 如果审核通过，需要显示完整流程的节点，按退货退款、仅退款、保修三个流程分别构建
  // 判断是否审核后撤销，显示撤销节点，并结束构建
  if (params.secondaryState === 'shipment_undo') {
    current = 'FINISH';
    stepItems.push({
      value: 'undo',
      title: '撤销申请',
      content: params.cancelTime,
    });
    return { stepItems, current };
  }
  // 如果是仅退款，添加退款中和退款成功
  if (params.aftersaleType === 'refund') {
    current = params.aftersaleFinishTime ? 'FINISH' : 'refunding';
    const items = [
      {
        value: 'refunding',
        title: '退款中',
        content: params.aftersaleFinishTime,
      },
      {
        value: 'finish',
        title: '退款成功',
        content: params.aftersaleFinishTime,
      },
    ];
    stepItems.push(...items);
    return { stepItems, current };
  }
  // 如果是退货退款，添加退货退款完整流程
  if (params.aftersaleType === 'refundAndReturns') {
    current =
      params.secondaryState === 'finish' ||
      params.secondaryState === 'rejected' ||
      params.secondaryState === 'signing_reject'
        ? 'FINISH'
        : params.secondaryState;
    const items = [
      {
        value: 'pending_shipment',
        title: '买家待发货',
        content: params.customerRefundTime,
      },
      {
        value: 'pending_sign',
        title: '退货中',
        content: params.merchantSigningTime,
      },
      {
        value: 'refunding',
        title: '退款中',
        content: params.aftersaleFinishTime,
        hidden: params.secondaryState === 'rejected' || params.secondaryState === 'signing_reject',
      },
      {
        value: 'finish',
        title:
          params.secondaryState === 'rejected' || params.secondaryState === 'signing_reject' ? '已拒绝' : '退款成功',
        content: params.aftersaleFinishTime,
      },
    ];
    stepItems.push(...items);
    return { stepItems, current };
  }
  // 如果是保修，添加保修完整流程
  if (params.aftersaleType === 'warranty') {
    current =
      params.secondaryState === 'finish' ||
      params.secondaryState === 'rejected' ||
      params.secondaryState === 'signing_reject'
        ? 'FINISH'
        : params.secondaryState;
    const items = [
      {
        value: 'pending_shipment',
        title: '买家待发货',
        content: params.customerRefundTime,
      },
      {
        value: 'pending_sign',
        title: '待收货',
        content: params.merchantSigningTime,
      },
      {
        value: 'servicing',
        title: '维修中',
        content: params.aftersaleFinishTime,
        hidden: params.secondaryState === 'rejected' || params.secondaryState === 'signing_reject',
      },
      {
        value: 'finish',
        title: params.secondaryState === 'rejected' || params.secondaryState === 'signing_reject' ? '已拒绝' : '已完成',
        content: params.aftersaleFinishTime,
      },
    ];
    stepItems.push(...items);
    return { stepItems, current };
  }
  return { stepItems, current };
};

/**
 * 仅支持正数，小数加法精度处理，小数部分和整数部分分开处理
 */
export function positiveAdd(num1: number, num2: number): number {
  if (!num1 || !num2) return (num1 || 0) + (num2 || 0);
  const r1 = num1.toString().split('.')[1]?.length || 0;
  const r2 = num2.toString().split('.')[1]?.length || 0;
  // 整数不存在精度问题，直接返回
  if (!r1 && !r2) return num1 + num2;
  let newNumber1 = num1;
  let newNumber2 = num2;
  const diff = Math.abs(r1 - r2);
  const digit = 10 ** Math.max(r1, r2);
  if (diff > 0) {
    const cm = 10 ** diff;
    if (r1 > r2) {
      newNumber1 = Number(num1.toString().replace('.', ''));
      newNumber2 = Number(num2.toString().replace('.', '')) * cm;
    } else {
      newNumber1 = Number(num1.toString().replace('.', '')) * cm;
      newNumber2 = Number(num2.toString().replace('.', ''));
    }
  } else {
    newNumber1 = Number(num1.toString().replace('.', ''));
    newNumber2 = Number(num2.toString().replace('.', ''));
  }
  return (newNumber1 + newNumber2) / digit;
}

/**
 * 正数，小数减法精度处理，小数部分和整数部分分开处理
 */
export function positiveSubtract(num1: number, num2: number): number {
  if (!num1 || !num2) return (num1 || 0) - (num2 || 0);
  const r1 = num1.toString().split('.')[1]?.length || 0;
  const r2 = num2.toString().split('.')[1]?.length || 0;
  const digit = 10 ** Math.max(r1, r2);
  const n = r1 >= r2 ? r1 : r2;
  return Number(((num1 * digit - num2 * digit) / digit).toFixed(n));
}

export function objectToQueryString(obj: object) {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

// 比对图片差异，如果有更改，返回有isChangeTag为true的图片数据
export const insertChangeTagInImage = (newImages: LaiFile[], oldImages: LaiFile[]) => {
  if (!Array.isArray(newImages) || !Array.isArray(oldImages)) {
    return newImages;
  }

  if (isEqual(newImages, oldImages)) return newImages;

  return newImages.map((v) => {
    const isHas = !oldImages.find((v2) => v2.id === v.id);

    return {
      ...v,
      isChangeTag: !!isHas,
    };
  });
};
