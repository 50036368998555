import 'nprogress/nprogress.css'; // progress bar style

import NProgress from 'nprogress'; // progress bar
import { MessagePlugin } from 'tdesign-vue-next';
import { RouteRecordRaw } from 'vue-router';

import { TOKEN_NAME } from '@/config/global';
import router from '@/router';
import { getPermissionStore, useUserStore } from '@/store';
import { PAGE_NOT_FOUND_ROUTE } from '@/utils/route/constant';

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  const permissionStore = getPermissionStore();
  const { whiteListRouters } = permissionStore;

  const userStore = useUserStore();
  if (userStore[TOKEN_NAME]) {
    if (to.path === '/login') {
      next();
      return;
    }
    try {
      const userInfo = await userStore.getUserInfo();
      console.log('userInfo', userInfo);

      // 如果供应商属于注销中状态，则跳转至注销中页面
      if (userInfo.suppliersStatus === 'destroying') {
        if (to.path === '/log-off-account-result') {
          next();
          return;
        }
        next({ path: '/log-off-account-result', replace: true });
        return;
      }

      if (userInfo.suppliersStatus === 'enable' && to.path === '/log-off-account-result') {
        next({ path: '/', replace: true });
        return;
      }

      /**
       * 如果供应商状态处于审核状态，则拦截跳转入驻页面
       * 如果供应商已经提交过入驻申请，则跳转到入驻申请结果页面
       */
      if (userInfo.suppliersStatus === 'audit' && to.path !== '/register' && to.path !== '/register/result') {
        if (userInfo.suppliersAuditStatus === 'unSubmit') {
          next({ path: '/register', replace: true });
        } else {
          next({ path: '/register/result', replace: true });
        }
        return;
      }

      const { asyncRoutes } = permissionStore;
      if (asyncRoutes && asyncRoutes.length === 0) {
        const routeList = await permissionStore.buildAsyncRoutes();

        routeList.forEach((item: RouteRecordRaw) => {
          router.addRoute(item);
        });

        if (to.name === PAGE_NOT_FOUND_ROUTE.name) {
          // 动态添加路由后，此处应当重定向到fullPath，否则会加载404页面内容
          next({ path: to.fullPath, replace: true, query: to.query });
        } else {
          const redirect = decodeURIComponent((from.query.redirect || to.path) as string);

          next(to.path === redirect ? { ...to, replace: true } : { path: redirect });
          return;
        }
      }

      if (userInfo.suppliersStatus !== 'audit' && (to.path === '/register' || to.path === '/register/result')) {
        next(`/`);
        return;
      }

      if (
        asyncRoutes.length > 0 &&
        userInfo.settledType === 'service' &&
        userInfo.storeInformationId === null &&
        to.path !== '/store-management/store-info'
      ) {
        if (to.path === '/result/404') {
          next();
          return;
        }

        MessagePlugin.error('暂时无法进行其它操作，请先完善门店信息！');
        next(`/store-management/store-info`);
        return;
      }
      if (router.hasRoute(to.name)) {
        next();
      } else {
        next(`/`);
      }
    } catch (error) {
      next({
        path: '/login',
        query: { redirect: encodeURIComponent(to.fullPath) },
      });
      NProgress.done();
    }
  } else {
    /* white list router */
    if (whiteListRouters.indexOf(to.path) !== -1) {
      next();
    } else {
      next({
        path: '/login',
        query: { redirect: encodeURIComponent(to.fullPath) },
      });
    }
    NProgress.done();
  }
});

router.afterEach((to) => {
  if (to.path === '/login') {
    const userStore = useUserStore();
    const permissionStore = getPermissionStore();

    userStore.logout();
    permissionStore.restoreRoutes();
  }
  NProgress.done();
});
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.href = to.fullPath;
  }
});
