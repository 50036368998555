import { replace } from 'lodash';

import type {
  GetSuppliersDestroyConditionResponse,
  IDownloadTransferDoc,
  ISubmitSuppliersTransferRequest,
  ISuppliersChangeInfo,
  ISuppliersTransferInfo,
  LicenseOcrInfoResponse,
  LoginByPhoneParams,
  updateSuppliersMainInfoRequest,
} from '@/api/model/userModel';
import { request } from '@/utils/request';

import { BaseResponse } from './model/common';

const Api = {
  LoginByPhone: '/non-auth/user/phone/login',
  SendSmsForLogin: '/non-auth/sms/send/login/:phone',
  UserInfo: '/api/user/info',
  SuppliersInboundInfo: '/api/suppliers/manage/inbound/info',
  SuppliersInboundApply: '/api/suppliers/manage/inbound/apply',
  GetSuppliersInfo: '/api/suppliers/manage/info',
  SuppliersContactInfo: '/api/suppliers/manage/edit',
  Downloademplacement: '/api/suppliers/manage/down/emplacement',
  CloseLoginSSE: '/non-auth/sse/close',
  GetSuppliersChangeInfo: '/api/suppliers/manage/change/info',
  SaveSuppliersMainInfo: '/api/suppliers/manage/change/edit',
  RevocationSuppliersChangeInfo: '/api/suppliers/manage/change/revocation',
  GetTransferIinfo: '/api/suppliers/manage/transfer/info',
  SaveSuppliersTransfer: '/api/suppliers/manage/transfer/edit',
  RevocationSuppliersTransfer: '/api/suppliers/manage/transfer/revocation',
  DownloadTransferDoc: '/api/suppliers/manage/down/transfer',
  GetLicenseOcrInfo: '/api/suppliers/manage/license/ocr',
  GetSupplersIdCardOcr: '/api/suppliers/manage/id-card/ocr',
  GetSupplersIdCardReverseOcr: '/api/suppliers/manage/id-card/reverse/ocr',
  SubmitMainTransfeApply: '/api/suppliers/manage/transfer/submit',
  GetSuppliersChangeAgreemt: '/api/suppliers/manage/down/change/agreement',
  SubmitSuppliersChange: '/api/suppliers/manage/change/submit',
  GetSuppliersDestroyCondition: '/api/suppliers/manage/destroy/condition',
  GetSuppliersDestroyAgreement: '/api/suppliers/manage/destroy/agreement',
  SendSuppliersDestroyCode: '/api/suppliers/manage/destroy/send/code',
  SubmitSuppliersDestroy: '/api/suppliers/manage/destroy/apply',
  CancelSuppliersDestroyInvocation: '/api/suppliers/manage/destroy/invocation',
};

export function loginByPhone(params: LoginByPhoneParams) {
  return request.post({
    url: Api.LoginByPhone,
    data: params,
  });
}

export function sendSmsForLogin(phone: string) {
  return request.post({
    url: replace(Api.SendSmsForLogin, ':phone', phone),
  });
}

export function getUserInfo() {
  return request.get({
    url: Api.UserInfo,
  });
}

export function saveSuppliersInboundInfo(data) {
  return request.post({
    url: Api.SuppliersInboundInfo,
    data,
  });
}

export function getSuppliersInboundInfo() {
  return request.get({
    url: Api.SuppliersInboundInfo,
  });
}

export function suppliersInboundApply(data) {
  return request.put({
    url: Api.SuppliersInboundApply,
    data,
  });
}

export function getSuppliersInfo() {
  return request.get({
    url: Api.GetSuppliersInfo,
  });
}

export function suppliersContactInfo(data) {
  return request.post({
    url: Api.SuppliersContactInfo,
    data,
  });
}

export function downloademplacement() {
  return request.get({
    url: Api.Downloademplacement,
  });
}

// 通知后端关闭登录的sse连接
export function closeLoginSSE(clientId: string) {
  return request.get({
    url: `${Api.CloseLoginSSE}/${clientId}`,
  });
}

// 获取供应商主体变更审核信息
export function getSuppliersChangeInfo() {
  return request.get<BaseResponse<ISuppliersChangeInfo>>({
    url: Api.GetSuppliersChangeInfo,
  });
}
// 供应商主体变更信息保存
export function saveSuppliersMainInfo(data: updateSuppliersMainInfoRequest) {
  return request.post({
    url: Api.SaveSuppliersMainInfo,
    data,
  });
}

// 供应商主体变更申请提交
export function submitSuppliersChange() {
  return request.post({
    url: Api.SubmitSuppliersChange,
  });
}
// 撤销主体变更申请
export function cancelSuppliersChangeInfo(id: string) {
  return request.delete<BaseResponse>({
    url: Api.RevocationSuppliersChangeInfo,
    params: `/${id}`,
  });
}

// 获取主体变更协议
export function getSuppliersChangeAgreemt() {
  return request.get({
    url: Api.GetSuppliersChangeAgreemt,
  });
}

// 获取供应商过户信息
export function getSuppliersTransferInfo() {
  return request.get<BaseResponse<ISuppliersTransferInfo>>({
    url: Api.GetTransferIinfo,
  });
}

// 主体过户信息保存
export function saveSuppliersTransferInfo(data: ISubmitSuppliersTransferRequest) {
  return request.post<BaseResponse>({
    url: Api.SaveSuppliersTransfer,
    data,
  });
}
// 撤销主体过户申请
export function cancelSuppliersTransfer(id: string) {
  return request.delete<BaseResponse>({
    url: Api.RevocationSuppliersTransfer,
    params: `/${id}`,
  });
}

// 供应商主体过户提交
export function submitMainTransfeApply(data: ISubmitSuppliersTransferRequest) {
  return request.post<BaseResponse>({
    url: Api.SubmitMainTransfeApply,
    data,
  });
}

// 下载主体过户申请书
export function downloadTransferDoc() {
  return request.get<BaseResponse<IDownloadTransferDoc>>({
    url: Api.DownloadTransferDoc,
  });
}

// 获取营业执照识别信息
export function getLicenseOcrInfo(imgUrl: string) {
  return request.post<LicenseOcrInfoResponse>({
    url: Api.GetLicenseOcrInfo,
    data: {
      imgUrl,
    },
  });
}
// 身份证正面信息提取
export function getSupplersIdCardOcr(imgUrl: string) {
  return request.post({
    url: Api.GetSupplersIdCardOcr,
    data: {
      imgUrl,
    },
  });
}

// 身份证反面信息提取
export function getSupplersIdCardReverseOcr(imgUrl: string) {
  return request.post({
    url: Api.GetSupplersIdCardReverseOcr,
    data: {
      imgUrl,
    },
  });
}

// 获取供应商注销待确认信息
export function getSuppliersDestroyCondition() {
  return request.get<GetSuppliersDestroyConditionResponse>({
    url: Api.GetSuppliersDestroyCondition,
  });
}

// 获取供应商注销协议
export function getSuppliersDestroyAgreement() {
  return request.get<BaseResponse<{ destroyAgreement: string }>>({
    url: Api.GetSuppliersDestroyAgreement,
  });
}

// 发送供应商注销验证码
export function sendSuppliersDestroyCode() {
  return request.get({
    url: Api.SendSuppliersDestroyCode,
  });
}

// 提交供应商注销申请
export function submitSuppliersDestroy(data: { code: string; reason: string }) {
  return request.post({
    url: Api.SubmitSuppliersDestroy,
    data,
  });
}

// 撤销注销申请
export function cancelSuppliersDestroyInvocation() {
  return request.post({
    url: Api.CancelSuppliersDestroyInvocation,
  });
}
